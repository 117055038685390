(function () {
    jQuery(function ($) {
        $('.small-menu-toggle').click(function () {
            $('.main-navigation').slideToggle();
            $('.header-cta').toggle();
        });
        $('.small-search-toggle').click(function () {
            $('#js-search-container').addClass('search-show');
            $('#js-search-container .search-field').focus();
        });
        $('.search-field').blur(function () {
            $('#js-search-container').removeClass('search-show');
        });
        if (($('.call-to-action-container').length) || ($('.js-stickyHead').length)) {
            var $window = $(window),
            $totalPrice = $('.call-to-action-container'),
            $nav = $('.js-stickyHead'),
            // elTop  = ($totalPrice.offset().top);
            navTop = ($('.site-header').offset().top);
            $window.scroll(function () {
                if ($totalPrice) {
                    $totalPrice.toggleClass('js-fixed', $window.scrollTop() > (navTop + 85));
                }
                if ($nav) {
                    $nav.toggleClass('js-fixed', $window.scrollTop() > (navTop + 85));
                }
            });
        }
        $('.action-item').hover(function () {
            $(this).addClass('ai-highlight');
            $(this).siblings().removeClass('ai-highlight');
        });
    });
})($);